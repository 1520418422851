
<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <v-card>
        <v-toolbar flat color="primary" dark>
            <v-toolbar-title>List Countries</v-toolbar-title>
             <v-spacer></v-spacer>

            <v-btn outlined @click="AddCountry">
              <v-icon>mdi-plus</v-icon>Add Country               
            </v-btn>
        </v-toolbar>
        <v-list subheader two-line>
            <v-list-item v-for="countryOption in countryList" :key="countryOption.title">
                <v-list-item-content>
                    <v-list-item-title v-text="countryOption.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon @click="DeleteCountry(countryOption)">
                    <v-icon >mdi-delete-empty</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
        </v-list>
    </v-card>
    <v-dialog v-model="dialogAddCountry" max-width="1000px">
        <v-card>
            <v-form ref="form" v-model="validForm"  @submit.prevent="save" lazy-validation>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="countryName" :rules="nameRules" label="Name" outlined prepend-icon="mdi-account" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text type="submit">
                    Save
                  </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteUserConfirm" :disabled="dialogLoading" :loading="dialogLoading">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          text
          color="red"
          @click="sheet = !sheet"
        >
          close
        </v-btn>
        <div class="py-3">
          This user is disabled
        </div>
      </v-sheet>
    </v-bottom-sheet> -->
  </v-container>
</template>
<script>
import { db, functions } from '../../../plugins/firebase'
export default {
  data () {
    return {
      alertError: '',
      alertSuccess: '',
      nameRules: [
        v => (v && v.length >= 2) || 'Minimum length is 2 characters',
        v => (v && v.length <= 60) || 'Name must be less than 60 characters'
      ],
      countryName: '',
      countryList: [],
      editedUID: -1,
      dialogAddCountry: false,
      dialogDelete: false,
      dialogLoading: false,
      validForm: true,
    }
  },
  created () {
    this.ListCountries();
  },
  methods: {
    async ListCountries () {
      await db.collection('schoolsData').doc('RegionSchoolD').get().then((doc) => {
        let Countries = doc.data().Countries;
        let ids = Object.keys(Countries);
        let newCountries = [];
        ids.forEach(element => {
          newCountries.push({ id: element, name: Countries[element] })
        });
        this.countryList = newCountries;
      }).catch((error) => {
        console.log(error)
      });
    },
    AddCountry () {
        this.dialogAddCountry = true;        
    },
    DeleteCountry (data) {
      console.log(data);
      this.editedUID = data.id;
      // this.user = Object.assign({}, data)
      this.dialogDelete = true
    },
    async deleteUserConfirm () {
      this.dialogLoading = true;
      let puedoEliminar = await this.VerificarEliminar();
      if (!puedoEliminar){
        this.alertError = "no se puede eliminar porque tiene distritos anexados";
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
      }else{
        console.log("eliminado");
        let asnwer = await this.deleteData(this.editedUID);
        console.log(answer);
        // this.deleteData(this.editedUID).then((answer) => {
        //   if (answer.result) {
        //     this.alertSuccess = "deleted successfully";
        //   } else {
        //     this.alertError = answer.message;
        //   }
        // });
      }
      this.dialogLoading = false;
      this.closeDelete()
    },
    close () {
      this.dialogAddCountry = false
      this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.stateName = '';
        this.editedUID = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        //this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    save () {
      console.log(this.stateName)
      if (!this.$refs.form.validate()) return;
        this.dialogLoad = true;
        this.createData(this.user).then(response => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "se creo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false

          this.close()
          this.$refs.form.resetValidation()
        });

    },
    validate () {
      this.$refs.form.validate()
    },
    // llamadas a firebase
    async createData () {
      let answer = { result: '', message: '' }
      try {
        let uidSuperAdmin = this.$store.state.user;
        if (uidSuperAdmin == null) {
          throw new Error('no existe el usuario');
        }
        let docRef = await db.collection('schoolsData').doc('RegionSchoolD').collection('Countries').add({ "States": {} })
        console.log(docRef);
        let answer = await db.collection('schoolsData').doc('RegionSchoolD').update({  [`Countries.${[docRef.id]}`]: this.countryName   })
        this.ListCountries();
      } catch (error) {
        console.log(error)
        answer.result = false;
        answer.message = error;
      }
      return answer;
    },
    async deleteData (id) {
      let answer = { resutl: '', message: '' }
      let uidLogin = this.$store.state.user.uid;
      if (uidLogin === id) {
        answer.mensaje = 'this user is actually login';
        answer.resultado = false;
        return answer;
      }
      try {
        const deleteAdmin = functions.httpsCallable('deleteState')
        await deleteAdmin(id)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.message = error;
        answer.resutl = false;
      }
      return answer;
    },
    async VerificarEliminar(){
      try {
        let stateDoc = await db.collection('schoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection("States").doc(this.editedUID).get();
        let nroDocSchoolDistricts = Object.keys(stateDoc.data().SchoolDistricts).length;
        console.log(nroDocSchoolDistricts);
        if (nroDocSchoolDistricts>0) 
          return false;
        let schoolDistrictDoc = await db.collection('schoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection("States").doc(this.editedUID).collection("SchoolDistricts").get();
        console.log(schoolDistrictDoc.docs.length);
        if (schoolDistrictDoc.docs.length > 0)
          return false;
        return true; // esto devolvera true solo si puede eliminar
      } catch (error) {
        console.log(error);
        return false;
      }    
    }
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New Country' : 'Edit Country'
    },
  },
  watch: {
    dialogSchoolDistrict (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogLoad (val) {
      console.log(val)
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
